@keyframes blink_animation {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.back-animation {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (max-width: 768px) {
  .back-animation {
    background-position: center calc(50% - 30vw);
  }
}

.back-animation.show {
  background-image: var(--backSVG);
}

.kv-title {
  z-index: 1;
  pointer-events: none;
}

.kv {
  width: 100%;
  height: 100vh;
  background: linear-gradient(-45deg, #b4c5b4 25%, #ebd7cc);
}

.kv-title {
  display: grid;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.kv-title.show .kv-title__sub {
  background-position: left center;
}

@media screen and (max-width: 768px) {
  .kv-title {
    top: 70%;
  }
}

.kv-title__main {
  width: 61.56%;
  margin: 0 auto;
  margin-bottom: 3vw;
}

@media screen and (max-width: 768px) {
  .kv-title__main {
    width: 89.86%;
    margin-bottom: 7.5vw;
  }
}

.kv-title__main svg {
  width: 100%;
  display: block;
  pointer-events: none;
}

.kv-title__sub {
  font-size: 2.37vw;
  color: transparent;
  font-weight: bold;
  letter-spacing: 0.12em;
  display: inline-block;
  margin: 0 auto;
  background-image: linear-gradient(90deg, white 50%, #d4d4d4 50%);
  background-size: 201% 100%;
  background-position: right center;
  -webkit-background-clip: text;
  background-clip: text;
  transition: background-position 0.326s linear calc(.5s + .326s);
}

@media screen and (max-width: 768px) {
  .kv-title__sub {
    font-size: 5.1vw;
    margin: 0;
    padding-left: 4.5%;
    transition: background-position 0.4s linear calc(.5s + .169s + 0.457s);
  }
}

.kv-scroll {
  width: 6.25vw;
  height: 100vh;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  pointer-events: none;
  transition: opacity 0.5s ease-out 1s;
  opacity: 0;
}

.kv-scroll.show {
  opacity: 1;
}

.kv-scroll__text {
  color: #fff;
  position: absolute;
  font-size: 1vw;
  left: 1.1vw;
  bottom: 4.6vw;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 768px) {
  .kv-scroll__text {
    font-size: 2.4vw;
    left: -1.5vw;
    bottom: 8.4vw;
  }
}

.kv-scroll__bar {
  width: 0.13vw;
  height: 4.38vw;
  background-color: #fff;
  margin: 0 auto;
  position: absolute;
  overflow: hidden;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 768px) {
  .kv-scroll__bar {
    width: 0.3vw;
    height: 8vw;
  }
}

.kv-scroll__bar:before {
  content: " ";
  display: block;
  width: 100%;
  height: 42.8%;
  background-color: #48c095;
  position: absolute;
  left: 0;
  top: 0;
  animation: scrollbar 2s ease-out infinite;
}

@keyframes scrollbar {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(235%);
  }
}

.para-area {
  width: 100%;
  height: 53.13vw;
  overflow: hidden;
  margin-bottom: 13.97vw;
  background-color: #000;
}

@media screen and (max-width: 768px) {
  .para-area {
    height: 93.13vw;
    margin-bottom: 24vw;
  }
}

.para-area__inner {
  backface-visibility: hidden;
}

.para-area__inner img {
  position: relative;
  display: block;
}

.box {
  width: 100%;
}

.box:last-child {
  margin-bottom: 12.5vw;
}

@media screen and (max-width: 768px) {
  .box:last-child {
    margin-bottom: 46.9vw;
  }
}

.box-inner {
  width: 91.25%;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .box-inner {
    width: 89.33%;
  }
}

.box-title {
  text-align: center;
  color: #01b577;
  margin-bottom: 4.3vw;
}

@media screen and (max-width: 768px) {
  .box-title {
    margin-bottom: 10vw;
  }
}

.box-title.show span {
  background-position: left center;
}

.box-title__main {
  font-size: 10.31vw;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  .box-title__main {
    font-size: 22vw;
    line-height: 0.9;
  }
}

.box-title__main span {
  color: transparent;
  line-height: inherit;
  background-image: linear-gradient(90deg, #01b577 50%, #d4d4d4 50%);
  background-size: 201% 100%;
  background-position: right center;
  -webkit-background-clip: text;
  background-clip: text;
  transition: background-position var(--time) linear;
}

.box-title__sub {
  font-size: 2.12vw;
  line-height: 1.52;
  font-weight: bold;
}

.box-title__sub span {
  color: transparent;
  line-height: inherit;
  background-image: linear-gradient(90deg, #01b577 50%, #d4d4d4 50%);
  background-size: 201% 100%;
  background-position: right center;
  -webkit-background-clip: text;
  background-clip: text;
  transition: background-position var(--time) linear var(--delay);
}

@media screen and (max-width: 768px) {
  .box-title__sub {
    font-size: 5.36vw;
    line-height: 1.45;
  }
}

.link-btn {
  font-size: 1vw;
  width: 16.25vw;
  height: 4.38vw;
  text-align: center;
  line-height: 4.28vw;
  border-radius: 4.38vw;
  background-color: #333333;
  margin: 0 auto 6.26vw;
  overflow: hidden;
  transition: background-color 0.3s cubic-bezier(0.63, 0, 0.45, 1);
}

@media screen and (max-width: 768px) {
  .link-btn {
    width: 100%;
    height: 16vw;
    font-size: 3.45vw;
    border-radius: 16vw;
    line-height: 16vw;
    margin: 0 auto 13.4vw;
  }
}

.link-btn:after {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4.38vw;
  background-color: #01b577;
  transition: transform 0.5s cubic-bezier(0.63, 0, 0.45, 1);
  transform: translateY(102%);
}

.link-btn a,
.link-btn a:link,
.link-btn a:visited {
  position: relative;
  display: block;
  color: #fff;
  z-index: 1;
}

@media screen and (min-width: 769px) {
  .link-btn:hover {
    background-color: #01b577;
    transition: background-color 0.5s cubic-bezier(0.63, 0, 0.45, 1) 0.2s;
  }
  .link-btn:hover:after {
    transform: translateY(0);
  }
}

.box1 {
  margin-bottom: 14vw;
}

@media screen and (max-width: 768px) {
  .box1 {
    margin-bottom: 31.1vw;
  }
}

.box1 .box-text-area {
  margin-bottom: 4.77vw;
}

@media screen and (max-width: 768px) {
  .box1 .box-text-area {
    margin-bottom: 11.1vw;
  }
}

.box1 .box-text-area p {
  font-size: 1.5vw;
  line-height: 2.08;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .box1 .box-text-area p {
    font-size: 4.53vw;
    line-height: 1.76;
    text-align: left;
  }
}

.box1-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .box1-list {
    width: 100%;
  }
}

.box1-list__item {
  -webkit-box-flex: 0;
  flex: 0 0 30%;
  border-radius: 2.8vw;
  aspect-ratio: 439/577;
  background-repeat: no-repeat;
  transform: perspective(5000px) rotateX(-60deg) scale(0.6);
  opacity: 0;
  transition: transform 1s cubic-bezier(0.22, 0.61, 0.36, 1) calc(.1s + (.1s * var(--index))), opacity 1s ease-out calc(.1s + (.1s * var(--index)));
  backface-visibility: hidden;
}

@media screen and (max-width: 768px) {
  .box1-list__item {
    flex: 0 0 100%;
    aspect-ratio: 670/530;
    border-radius: 6vw;
    margin-bottom: 4vw;
  }
  .box1-list__item:last-child {
    margin-bottom: 0;
  }
}

.box1-list__item.show {
  transform: perspective(5000px) rotateX(0deg) scale(1);
  opacity: 1;
}

.box1-list__item:nth-child(1) {
  background-color: #01b577;
  background-size: 150%;
  background-position: 50% -50%;
  transform-origin: 150% top;
  perspective-origin: 200% top;
}

@media screen and (max-width: 768px) {
  .box1-list__item:nth-child(1) {
    transform-origin: center top;
    background-position: 20% 35%;
  }
}

.box1-list__item:nth-child(2) {
  background-color: #18a3c1;
  background-size: 95%;
  background-position: 50% 45%;
  transform-origin: center top;
  perspective-origin: center top;
}

@media screen and (max-width: 768px) {
  .box1-list__item:nth-child(2) {
    background-size: 160%;
    background-position: 50% 50%;
  }
}

.box1-list__item:nth-child(3) {
  background-color: #ebd22a;
  background-size: 80%;
  background-position: 50% 90%;
  transform-origin: -50% top;
  perspective-origin: -100% top;
}

@media screen and (max-width: 768px) {
  .box1-list__item:nth-child(3) {
    transform-origin: center top;
    background-size: 85%;
    background-position: 158% -56%;
  }
}

.box1-list__title {
  width: 100%;
  font-size: 3.62vw;
  position: absolute;
  left: 12.6%;
  top: 5vw;
  color: #fff;
  font-weight: bold;
  letter-spacing: -0.025em;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  .box1-list__title {
    font-size: 7.7vw;
    left: 11.4%;
    top: 9.4vw;
  }
}

.box1-list__pic img {
  display: block;
}

.box1-slider-icon {
  width: 16.86%;
  margin: 0 auto;
}

@keyframes icon {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.box2 {
  margin-bottom: 18.3vw;
}

@media screen and (max-width: 768px) {
  .box2 {
    margin-bottom: 22.8vw;
  }
}

.box2 .box-inner {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .box2 .box-inner {
    width: 89.33%;
  }
}

.box2 .box-title {
  margin-bottom: 1.9vw;
}

@media screen and (max-width: 768px) {
  .box2 .box-title {
    margin-bottom: 10.5vw;
  }
}

.box2 .box-title__main {
  margin-bottom: 4.3vw;
}

@media screen and (max-width: 768px) {
  .box2 .box-title__main {
    margin-bottom: 7.9vw;
  }
}

.box2 .box-text-area {
  margin-bottom: 5.2vw;
}

@media screen and (max-width: 768px) {
  .box2 .box-text-area {
    margin-bottom: 12.5vw;
  }
}

.box2 .box-text-area p {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .box2 .box-text-area p {
    line-height: 1.61;
    text-align: justify;
  }
}

.box2 .link-btn {
  margin-bottom: 2.7vw;
}

@media screen and (max-width: 768px) {
  .box2 .link-btn {
    margin-bottom: 32.2vw;
  }
}

.box2 .pic-area {
  width: 134%;
  left: -12.62%;
}

@media screen and (max-width: 768px) {
  .box2 .pic-area {
    width: 170.4%;
    left: -33.2%;
  }
}

.box2 .pic-area img {
  position: relative;
  display: block;
}

.box2 .pic-area__pic1 {
  width: 43.1%;
  position: absolute;
  left: 0;
  top: 6.1vw;
}

@media screen and (max-width: 768px) {
  .box2 .pic-area__pic1 {
    width: 56.68%;
    left: -5.8%;
    top: -21.6vw;
  }
}

.box2 .pic-area__pic2 {
  width: 12.87%;
  position: absolute;
  left: 45.39%;
  top: 8.46vw;
}

@media screen and (max-width: 768px) {
  .box2 .pic-area__pic2 {
    width: 16.91%;
    left: 34%;
    top: 35.1vw;
  }
}

.box2 .pic-area__pic3 {
  width: 39.41%;
  left: 61.2%;
}

@media screen and (max-width: 768px) {
  .box2 .pic-area__pic3 {
    width: 51.77%;
    left: 54.3%;
  }
}

.pic-area__pic4 {
  width: 43.57%;
  position: absolute;
  left: 46.76%;
  top: 20vw;
  top: 28vw;
  transform: translateX(-50%);
}

@media screen and (max-width: 768px) {
  .pic-area__pic4 {
    width: 56.59%;
    top: 8.9vw;
    top: 30.9vw;
  }
}

.pic-area__pic4 img {
  position: relative;
}

@media screen and (min-width: 769px) {
  .pic-area__pic4 img {
    padding-top: 4vw;
  }
}

.pic-area__pic4 img:nth-child(2), .pic-area__pic4 img:nth-child(3) {
  position: absolute;
  left: 0;
  top: 0;
}

.box3 {
  margin-bottom: 14.12vw;
}

@media screen and (max-width: 768px) {
  .box3 {
    margin-bottom: 19.9vw;
    margin-bottom: 30.9vw;
  }
}

.box3 .box-title {
  margin-bottom: 1.9vw;
}

@media screen and (max-width: 768px) {
  .box3 .box-title {
    margin-bottom: 10.6vw;
  }
}

.box3 .box-title__main {
  margin-bottom: 4.3vw;
}

@media screen and (max-width: 768px) {
  .box3 .box-title__main {
    margin-bottom: 7.9vw;
  }
}

.box3 .box-text-area {
  margin-bottom: 5.2vw;
}

@media screen and (max-width: 768px) {
  .box3 .box-text-area {
    margin-bottom: 12.5vw;
  }
}

.box3 .box-text-area p {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .box3 .box-text-area p {
    line-height: 1.61;
    text-align: justify;
  }
}

.box3 .link-btn-area {
  width: 45vw;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 6.1vw;
}

@media screen and (max-width: 768px) {
  .box3 .link-btn-area {
    width: 100%;
    display: block;
    margin-bottom: 13.3vw;
  }
}

.box3 .link-btn {
  width: 21.25vw;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .box3 .link-btn {
    width: 100%;
    margin-bottom: 5.4vw;
  }
  .box3 .link-btn:last-child {
    margin-bottom: 0;
  }
}

.box3 .link-btn:first-child {
  background-color: #01b577;
}

.box3 .link-btn:first-child:after {
  background-color: #333333;
}

@media screen and (min-width: 769px) {
  .box3 .link-btn:first-child:hover {
    background-color: #333333;
  }
}

@media screen and (max-width: 768px) {
  .box-slider {
    margin-bottom: 8vw;
  }
}

.box-slider-list {
  display: inline-flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .box-slider-list {
    width: 100%;
    margin-bottom: 5.4vw;
    flex-wrap: nowrap;
    overflow: scroll;
    justify-content: flex-start;
    -webkit-overflow-scrolling: touch;
  }
  .box-slider-list::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .box-slider-list.active {
    animation: slider 20s linear infinite;
  }
}

@media screen and (min-width: 769px) {
  .box-slider-list:hover {
    animation-play-state: paused;
  }
}

@keyframes slider {
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

.box-slider-list__item {
  width: 25vw;
  border-radius: 3vw;
  padding: 3.3vw 3vw 2.6vw;
  background-color: #f8f0eb;
  margin-right: 1.8vw;
}

@media screen and (max-width: 768px) {
  .box-slider-list__item {
    -webkit-box-flex: 0;
    flex: none;
    width: 85.34vw;
    border-radius: 6vw;
    padding: 11vw 10.4vw 9.7vw;
    margin-right: 5.3vw;
  }
  .box-slider-list__item:first-child {
    margin-left: 5.3vw;
  }
  .box-slider-list__item:last-child {
    margin-right: 5.3vw;
  }
}

.box-slider-list__pic {
  width: 5.94vw;
  margin-bottom: 2.1vw;
}

@media screen and (max-width: 768px) {
  .box-slider-list__pic {
    width: 20vw;
    margin-bottom: 6.8vw;
  }
}

.box-slider-list__pic img {
  display: block;
}

.box-slider-list__text p {
  text-align: justify;
}

@media screen and (max-width: 768px) {
  .box-slider-list__text p {
    line-height: 1.61;
  }
}

.box-slider-icon {
  width: 14.9%;
  margin: 0 auto;
}

.box-slider-icon > div {
  animation: icon2 2s cubic-bezier(1, 0.18, 0.55, 0.01) infinite;
}

.box-slider-icon img {
  position: relative;
  display: block;
}

.box-slider-icon img.icon-anime {
  animation: icon 2s cubic-bezier(0.63, 0, 0.45, 1) infinite;
}

.box-slider-icon img:nth-child(2) {
  position: absolute;
  left: 0;
  top: 0;
}

@keyframes icon2 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.box3-para-pic {
  width: 100%;
  height: 47.5vw;
  overflow: hidden;
  margin-top: -6.2vw;
  z-index: -1;
}

@media screen and (max-width: 768px) {
  .box3-para-pic {
    height: 75vw;
    margin-top: 0;
  }
}

.box3-para-pic .box3-para-pic__inner {
  backface-visibility: hidden;
}

.box3-para-pic img {
  position: relative;
  display: block;
}

.box4 .link-btn {
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .box4 .news-area {
    padding-top: 7.3vw;
  }
}

.news-area {
  margin-bottom: 4.96vw;
}

@media screen and (max-width: 768px) {
  .news-area {
    margin-bottom: 13.1vw;
  }
}

.news-category {
  display: flex;
  margin-bottom: 2.45vw;
}

@media screen and (max-width: 768px) {
  .news-category {
    justify-content: space-between;
    margin-bottom: 10.3vw;
  }
}

.news-category__item {
  transition: color 0.3s ease-out, -webkit-text-stroke 0.3s ease-out;
  font-size: 1vw;
  margin-right: 2.4vw;
  color: #c7ccc7;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .news-category__item {
    font-size: 3.7vw;
    margin-right: 0;
  }
}

.news-category__item.active {
  -webkit-text-stroke: 0.065vw #01b577;
  color: #01b577;
}

@media screen and (max-width: 768px) {
  .news-category__item.active {
    -webkit-text-stroke: 0.2vw #01b577;
  }
}

@media screen and (min-width: 769px) {
  .news-category__item:hover {
    -webkit-text-stroke: 0.065vw #01b577;
    color: #01b577;
  }
}

.news-area-list__item {
  display: flex;
  align-items: baseline;
  padding: 2.45vw 0 2.56vw;
  border-bottom: 1px solid #eaeaea;
}

@media screen and (max-width: 768px) {
  .news-area-list__item {
    flex-wrap: wrap;
    border-bottom: 0.3vw solid #eaeaea;
    padding: 6.8vw 0;
  }
}

.news-area-list__item:first-child {
  border-top: 1px solid #eaeaea;
}

@media screen and (max-width: 768px) {
  .news-area-list__item:first-child {
    border-top: 0.3vw solid #eaeaea;
  }
}

.news-area-list__item.pdf {
  padding: 2.4vw 0 2.3vw;
}

@media screen and (max-width: 768px) {
  .news-area-list__item.pdf {
    padding: 6.8vw 0;
  }
}

@media screen and (max-width: 768px) {
  .news-area-list__item.pdf a,
  .news-area-list__item.pdf a:link,
  .news-area-list__item.pdf a:visited {
    width: 90%;
    display: block;
  }
}

.news-area-list__date {
  width: 8.2vw;
  font-size: 0.875vw;
  font-weight: 500;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 768px) {
  .news-area-list__date {
    width: 30.3vw;
    font-size: 3.45vw;
    margin-bottom: 2.8vw;
  }
}

.news-area-list__category {
  width: 5.94vw;
  font-size: 0.875vw;
  color: #01b577;
}

@media screen and (max-width: 768px) {
  .news-area-list__category {
    width: calc(100% - 30.3vw);
    font-size: 3.45vw;
  }
}

.news-area-list__text {
  width: calc(100% - 14.14vw);
  font-size: 1vw;
}

@media screen and (max-width: 768px) {
  .news-area-list__text {
    width: 100%;
    font-size: 3.45vw;
    text-align: justify;
    line-height: 1.61;
  }
}

.news-area-list__text > div {
  width: 1.44vw;
  height: 2vw;
  background-image: url(/common/img/ir-news-icon.png);
  background-size: 1.44em auto;
  background-position: right center;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.6em;
}

@media screen and (max-width: 768px) {
  .news-area-list__text > div {
    width: 6.14vw;
    height: 8vw;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-size: 100% auto;
  }
}

.news-area-list__text a,
.news-area-list__text a:link,
.news-area-list__text a:visited {
  transition: color 0.3s ease-out, -webkit-text-stroke 0.3s ease-out;
}

@media screen and (min-width: 769px) {
  .news-area-list__text a:hover,
  .news-area-list__text a:link:hover,
  .news-area-list__text a:visited:hover {
    -webkit-text-stroke: 0.065vw #01b577;
    color: #01b577;
  }
}
