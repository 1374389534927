@charset "UTF-8";

$max_width_pc:2000px;
$min_width_pc:960px;
$sp_width:768px;//bootstrap md

$bezier1:cubic-bezier(.13,.38,.12,1);
$bezier2:cubic-bezier(.18,.45,.25,1);

$tab_open_clode_ease:cubic-bezier(.27,.03,.37,1);

@mixin SpScale(){
	@media screen and (max-width:$sp_width){
	@content;
	}
}

@mixin PcScale(){
	@media screen and (min-width:$sp_width+1){
		@content;
	}
}


@mixin MaxScale($size){
    @media screen and (max-width:$size){
  @content;
  }
}

@mixin MinScale($size){
    @media screen and (min-width:$size){
  @content;
  }
}



%clearfix:after{
	content:".";
	font-size: 0;
	display: block;
	height: 0;
	visibility: hidden;
	clear: both;
	line-height: 0
}





//--------------mixinを使ってスタイルの使い回しが可能

//影付き角丸ボタン
@mixin btn-a {
	display: block;
	position: relative;
	overflow: hidden;

	&-hover {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		overflow: hidden;
		width: 100%;
		transition: transform 0.3s cubic-bezier(0.875, 0.190, 0.775, 0.855);
		transform: translateX(-100%);

		img {
			max-width: none;
			display: block;
			width: 100%;
			transition: transform 0.3s cubic-bezier(0.875, 0.190, 0.775, 0.855);
			transform: translateX(100%);
		}

	}
	&:hover {
		opacity: 1;
	}
	&:hover &{
		&-hover {
			transform: translateX(0%);

			img {
				transform: translateX(0%);
			}
		}
	}
}


//ブリンク

@mixin blink {
	&:hover {
		@media screen and (min-width: $sp_width+1) {
			animation:blink_animation 0.6s ease-out;
		}
	}
}


@keyframes blink_animation{
	0%{opacity:0}
	10%{opacity:0}
	100%{opacity:1}
}


@mixin spResponsive($prop,$vwNum) {
	#{$prop}: $vwNum + vw;
	@media screen and (min-width:0px) and (max-width:320px) {
		#{$prop}: 320*$vwNum/100/10 + rem;
	}
	@media screen and (min-width:$min_width_tab+1) and (max-width:$min_width_sp) {
		#{$prop}: 600*$vwNum/100/10 + rem;
	}
}



