@import "../common/css/_property"; //独自便利プロパティ集

// .canvasarea{
// 	width: 100%;
// 	height: 100%;
// 	position: absolute;
// 	left:0;
// 	top:0;
// }

.back-animation{
	width: 100%;
	height: 100%;
	position: absolute;
	left:0;
	top:0;;
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;

  @include SpScale {
  background-position: center calc(50% - 30vw);
  }
}
.back-animation.show{
  background-image: var(--backSVG);
}

.kv-title{
z-index: 1;
pointer-events: none;
}

.kv {
  width: 100%;
  height: 100vh;
  background: linear-gradient(-45deg, #b4c5b4 25%, #ebd7cc);
}

.kv-title {
  display: grid;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;

  &.show {
    .kv-title__sub {
      background-position: left center;
    }
  }

  @include SpScale{
    top: 70%;
  }
}

.kv-title__main {
  width: 61.56%;
  margin: 0 auto;
  margin-bottom: 3vw;

  @include SpScale {
    width: 89.86%;
    margin-bottom: 7.5vw;
  }

  svg {
    width: 100%;
    display: block;
    pointer-events: none;
  }
}

.kv-title__sub {
  font-size: 2.37vw;
  color: transparent;
  font-weight: bold;
  letter-spacing: 0.12em;
  display: inline-block;
  margin: 0 auto;
  // text-align: center;
  // transition: opacity 1s ease-out 0.5s;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 1) 50%, rgba(212, 212, 212, 1) 50%);
  background-size: 201% 100%;
  background-position: right center;
  -webkit-background-clip: text;
  background-clip: text;
  transition: background-position 0.326s linear calc(.5s + .326s);

  @include SpScale {
    font-size: 5.1vw;
    margin: 0;
    padding-left: 4.5%;
    transition: background-position 0.4s linear calc(.5s + .169s + 0.457s);
  }
}

.kv-scroll{
  width: 6.25vw;
  height: 100vh;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  pointer-events: none;
  transition: opacity 0.5s ease-out 1s;
  opacity: 0;

  &.show{
    opacity: 1;
  }
}

.kv-scroll__text{
  color: #fff;
  position: absolute;
  font-size: 1vw;
  left: 1.1vw;
  bottom: 4.6vw;
  letter-spacing: 0.05em;

  @include SpScale {
    font-size: 2.4vw;
    left: -1.5vw;
    bottom: 8.4vw;
  }
}

.kv-scroll__bar{
  width: 0.13vw;
  height: 4.38vw;
  background-color: #fff;
  margin: 0 auto;
  position: absolute;
  overflow: hidden;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  @include SpScale {
    width: 0.3vw;
    height: 8vw;
  }

  &:before{
    content: " ";
    display: block;
    width: 100%;
    height: 42.8%;
    background-color: #48c095;
    position: absolute;
    left: 0;
    top: 0;
    animation: scrollbar 2s ease-out infinite;
  }
}

@keyframes scrollbar {
  0% {
      transform: translateY(-100%);
  }

  100% {
      transform: translateY(235%);
  }
}

.para-area {
  width: 100%;
  height: 53.13vw;
  overflow: hidden;
  margin-bottom: 13.97vw;
  background-color: #000;

  @include SpScale {
    height: 93.13vw;
    margin-bottom: 24vw;
  }
}

.para-area__inner {
  backface-visibility: hidden;

  img {
    position: relative;
    display: block;
  }
}

.box {
  width: 100%;

  &:last-child {
    margin-bottom: 12.5vw;

    @include SpScale {
      margin-bottom: 46.9vw;
    }
  }
}

.box-inner {
  width: 91.25%;
  margin: 0 auto;

  @include SpScale {
    width: 89.33%;
  }
}

.box-title {
  text-align: center;
  color: #01b577;
  margin-bottom: 4.3vw;

  @include SpScale {
    margin-bottom: 10vw;
  }

  &.show {
    span {
      background-position: left center;
    }
  }
}

.box-title__main {
  font-size: 10.31vw;
  line-height: 1;

  @include SpScale {
    font-size: 22vw;
    line-height: 0.9;
  }

  span {
    color: transparent;
    line-height: inherit;
    background-image: linear-gradient(90deg, rgb(1, 181, 119) 50%, rgba(212, 212, 212, 1) 50%);
    background-size: 201% 100%;
    background-position: right center;
    -webkit-background-clip: text;
    background-clip: text;
    transition: background-position var(--time) linear;
  }
}

.box-title__sub {
  font-size: 2.12vw;
  line-height: 1.52;
  font-weight: bold;

  span {
    color: transparent;
    line-height: inherit;
    background-image: linear-gradient(90deg, rgb(1, 181, 119) 50%, rgba(212, 212, 212, 1) 50%);
    background-size: 201% 100%;
    background-position: right center;
    -webkit-background-clip: text;
    background-clip: text;
    transition: background-position var(--time) linear var(--delay);
  }

  @include SpScale {
    font-size: 5.36vw;
    line-height: 1.45;
  }
}

.link-btn {
  font-size: 1vw;
  width: 16.25vw;
  height: 4.38vw;
  text-align: center;
  line-height: 4.28vw;
  border-radius: 4.38vw;
  background-color: #333333;
  margin: 0 auto 6.26vw;
  overflow: hidden;
  transition: background-color 0.3s cubic-bezier(0.63, 0, 0.45, 1);

  @include SpScale {
    width: 100%;
    height: 16vw;
    font-size: 3.45vw;
    border-radius: 16vw;
    line-height: 16vw;
    margin: 0 auto 13.4vw;
  }

  &:after {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 4.38vw;
    background-color: #01b577;
    transition: transform 0.5s cubic-bezier(0.63, 0, 0.45, 1);
    transform: translateY(102%);
  }

  a,
  a:link,
  a:visited {
    position: relative;
    display: block;
    color: #fff;
    z-index: 1;
  }

  &:hover {
    @include PcScale {
      background-color: #01b577;
      transition: background-color 0.5s cubic-bezier(0.63, 0, 0.45, 1) 0.2s;

      &:after {
        transform: translateY(0);
      }
    }
  }
}

.box1 {
  margin-bottom: 14vw;

  @include SpScale {
    margin-bottom: 31.1vw;
  }

  .box-text-area {
    margin-bottom: 4.77vw;

    @include SpScale {
      margin-bottom: 11.1vw;
    }

    p {
      font-size: 1.5vw;
      line-height: 2.08;
      text-align: center;

      @include SpScale {
        font-size: 4.53vw;
        line-height: 1.76;
        text-align: left;
      }
    }
  }
}

.box1-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include SpScale {
    width: 100%;
  }
}

.box1-list__item {
  -webkit-box-flex: 0;
  flex: 0 0 30%;
  border-radius: 2.8vw;
    aspect-ratio: 439/577;
    background-repeat: no-repeat;
    transform: perspective(5000px) rotateX(-60deg) scale(.6);
    opacity: 0;
    transition: transform 1s cubic-bezier(0.22, 0.61, 0.36, 1) calc(.1s + (.1s * var(--index))) ,opacity 1s ease-out calc(.1s + (.1s * var(--index))) ;
    backface-visibility: hidden;

  @include SpScale {
    flex: 0 0 100%;
    aspect-ratio: 670/530;
    border-radius: 6vw;
    margin-bottom: 4vw;

    &:last-child{
      margin-bottom: 0;
    }
  }

    &.show{
    transform: perspective(5000px) rotateX(0deg) scale(1);
    opacity: 1;
    }

    &:nth-child(1){
        background-color: #01b577;
        background-size: 150%;
        background-position: 50% -50%;
        transform-origin: 150% top;
        perspective-origin: 200% top;

        @include SpScale {
          transform-origin: center top;
          background-position: 20% 35%;
        }
    }

  &:nth-child(2) {
    background-color: #18a3c1;
        background-size: 95%;
        background-position: 50% 45%;
        transform-origin: center top;
        perspective-origin: center top;

        @include SpScale {
          background-size: 160%;
          background-position: 50% 50%;
        }
  }

  &:nth-child(3) {
    background-color: #ebd22a;
        background-size: 80%;
        background-position: 50% 90%;
        transform-origin: -50% top;
        perspective-origin: -100% top;

        @include SpScale {
          transform-origin: center top;
          background-size: 85%;
          background-position: 158% -56%;
        }
  }
}

.box1-list__title {
  width: 100%;
  font-size: 3.62vw;
  position: absolute;
  left: 12.6%;
  top: 5vw;
  color: #fff;
  font-weight: bold;
  letter-spacing: -0.025em;
  line-height: 1.5;

  @include SpScale {
    font-size: 7.7vw;
    left: 11.4%;
    top: 9.4vw;
  }

}

.box1-list__pic {
  img {
    display: block;
  }
}

.box1-slider-icon {
  width: 16.86%;
  margin: 0 auto;
}

@keyframes icon {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.box2 {
  margin-bottom: 18.3vw;

  @include SpScale {
    margin-bottom: 22.8vw;
  }

  .box-inner {
    width: 100%;

    @include SpScale {
      width: 89.33%;
    }
  }

  .box-title {
    margin-bottom: 1.9vw;

    @include SpScale {
      margin-bottom: 10.5vw;
    }
  }

  .box-title__main {
    margin-bottom: 4.3vw;

    @include SpScale {
      margin-bottom: 7.9vw;
    }
  }

  .box-text-area {
    margin-bottom: 5.2vw;

    @include SpScale {
      margin-bottom: 12.5vw;
    }

    p {
      text-align: center;

      @include SpScale {
        line-height: 1.61;
        text-align: justify;
      }
    }
  }

  .link-btn {
    margin-bottom: 2.7vw;

    @include SpScale {
      margin-bottom: 32.2vw;
    }
  }

  .pic-area {
    width: 134%;
    left: -12.62%;

    @include SpScale {
      width: 170.4%;
      left: -33.2%;
    }

    img {
      position: relative;
      display: block;
    }
  }

  .pic-area__pic1 {
    width: 43.1%;
    position: absolute;
    left: 0;
    top: 6.1vw;

    @include SpScale {
      width: 56.68%;
      left: -5.8%;
      top: -21.6vw;
    }
  }

  .pic-area__pic2 {
    width: 12.87%;
    position: absolute;
    left: 45.39%;
    top: 8.46vw;

    @include SpScale {
      width: 16.91%;
      left: 34%;
      top: 35.1vw;
    }
  }

  .pic-area__pic3 {
    width: 39.41%;
    left: 61.2%;

    @include SpScale {
      width: 51.77%;
      left: 54.3%;
    }
  }
}

.pic-area__pic4{
  width: 43.57%;
  position: absolute;
  left: 46.76%;
  top: 20vw;
  top: 28vw;
  transform: translateX(-50%);

  @include SpScale {
    width: 56.59%;
    top: 8.9vw;
    top: 30.9vw;
  }

  img{
    position: relative;

    @include PcScale {
      padding-top: 4vw;
    }

    &:nth-child(2),
    &:nth-child(3){
      position: absolute;
      left: 0;top: 0;
    }
  }
}

.box3 {
  margin-bottom: 14.12vw;

  @include SpScale {
    margin-bottom: 19.9vw;
    margin-bottom: 30.9vw;
  }

  .box-title {
    margin-bottom: 1.9vw;

    @include SpScale {
      margin-bottom: 10.6vw;
    }
  }

  .box-title__main {
    margin-bottom: 4.3vw;

    @include SpScale {
      margin-bottom: 7.9vw;
    }
  }

  .box-text-area {
    margin-bottom: 5.2vw;

    @include SpScale {
      margin-bottom: 12.5vw;
    }

    p {
      text-align: center;

      @include SpScale {
        line-height: 1.61;
        text-align: justify;
      }
    }
  }

  .link-btn-area {
    width: 45vw;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 6.1vw;

    @include SpScale {
      width: 100%;
      display: block;
      margin-bottom: 13.3vw;
    }
  }

  .link-btn {
    width: 21.25vw;
    margin: 0;

    @include SpScale {
      width: 100%;
      margin-bottom: 5.4vw;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:first-child {
      background-color: #01b577;

      &:after {
        background-color: #333333;
      }

      &:hover {
        @include PcScale {
          background-color: #333333;
        }
      }
    }
  }
}

.box-slider{
  @include SpScale {
    margin-bottom: 8vw;
  }
}

.box-slider-list {
  display: inline-flex;
  justify-content: center;

  @include SpScale {
    width: 100%;
    margin-bottom: 5.4vw;
    flex-wrap: nowrap;
    overflow: scroll;
    justify-content: flex-start;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.active {
    @include PcScale {
      animation: slider 20s linear infinite;
    }
  }

  &:hover {
    @include PcScale {
      animation-play-state: paused;
    }
  }
}

@keyframes slider {
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

.box-slider-list__item {
  width: 25vw;
  border-radius: 3vw;
  padding: 3.3vw 3vw 2.6vw;
  background-color: #f8f0eb;
  margin-right: 1.8vw;
  //float: left;

  @include SpScale {
    -webkit-box-flex: 0;
    flex: none;
    width: 85.34vw;
    border-radius: 6vw;
    padding: 11vw 10.4vw 9.7vw;
    margin-right: 5.3vw;

    &:first-child {
      margin-left: 5.3vw;
    }

    &:last-child {
      margin-right: 5.3vw;
    }
  }
}

.box-slider-list__pic {
  width: 5.94vw;
  margin-bottom: 2.1vw;

  @include SpScale {
    width: 20vw;
    margin-bottom: 6.8vw;
  }

  img {
    display: block;
  }
}

.box-slider-list__text {
  p {
    text-align: justify;

    @include SpScale {
      line-height: 1.61;
    }
  }
}

.box-slider-icon {
  width: 14.9%;
  margin: 0 auto;

  &>div{
    animation: icon2 2s cubic-bezier(1,.18,.55,.01) infinite;
  }

  img{
    position: relative;
    display: block;

    &.icon-anime{
      animation: icon 2s cubic-bezier(0.63, 0, 0.45, 1) infinite;
    }

    &:nth-child(2){
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

@keyframes icon2 {
  0% {
    opacity: 0;
  }

  50%{
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.box3-para-pic{
  width: 100%;
  height: 47.5vw;
  overflow: hidden;
  margin-top: -6.2vw;
  z-index: -1;

  @include SpScale {
    height: 75vw;
    margin-top: 0;
  }

  .box3-para-pic__inner{
    backface-visibility: hidden;
  }

  img{
    position: relative;
    display: block;
  }
}

.box4 {
  .link-btn {
    margin: 0 auto;
  }

  .news-area {
    @include SpScale {
      padding-top: 7.3vw;
    }
  }
}

.news-area {
  margin-bottom: 4.96vw;

  @include SpScale {
    margin-bottom: 13.1vw;
  }
}

.news-category {
  display: flex;
  margin-bottom: 2.45vw;

  @include SpScale {
    justify-content: space-between;
    margin-bottom: 10.3vw;
  }
}

.news-category__item {
  transition: color 0.3s ease-out, -webkit-text-stroke 0.3s ease-out;
  font-size: 1vw;
  margin-right: 2.4vw;
  color: #c7ccc7;
  cursor: pointer;

  @include SpScale {
    font-size: 3.7vw;
    margin-right: 0;
  }

  &.active {
    -webkit-text-stroke: 0.065vw #01b577;
    color: #01b577;

    @include SpScale {
      -webkit-text-stroke: 0.2vw #01b577;
    }
  }

  &:hover {
    @include PcScale {
      -webkit-text-stroke: 0.065vw #01b577;
      color: #01b577;
    }
  }
}

.news-area-list__item {
  display: flex;
  align-items: baseline;
  padding: 2.45vw 0 2.56vw;
  border-bottom: 1px solid #eaeaea;

  @include SpScale {
    flex-wrap: wrap;
    border-bottom: 0.3vw solid #eaeaea;
    padding: 6.8vw 0;
  }

  &:first-child {
    border-top: 1px solid #eaeaea;

    @include SpScale {
      border-top: 0.3vw solid #eaeaea;
    }
  }

  &.pdf {
    padding: 2.4vw 0 2.3vw;

    @include SpScale {
      padding: 6.8vw 0;
    }

    a,
    a:link,
    a:visited {
      @include SpScale {
        width: 90%;
        display: block;
      }
    }
  }
}

.news-area-list__date {
  width: 8.2vw;
  font-size: 0.875vw;
  font-weight: 500;
  letter-spacing: 0.05em;

  @include SpScale {
    width: 30.3vw;
    font-size: 3.45vw;
    margin-bottom: 2.8vw;
  }
}

.news-area-list__category {
  width: 5.94vw;
  font-size: 0.875vw;
  color: #01b577;

  @include SpScale {
    width: calc(100% - 30.3vw);
    font-size: 3.45vw;
  }
}

.news-area-list__text {
  width: calc(100% - 14.14vw);
  font-size: 1vw;

  @include SpScale {
    width: 100%;
    font-size: 3.45vw;
    text-align: justify;
    line-height: 1.61;
  }

  &>div {
    width: 1.44vw;
    height: 2vw;
    background-image: url(/common/img/ir-news-icon.png);
    background-size: 1.44em auto;
    background-position: right center;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.6em;

    @include SpScale {
      width: 6.14vw;
      height: 8vw;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background-size: 100% auto;
    }
  }

  a,
  a:link,
  a:visited {
    transition: color 0.3s ease-out, -webkit-text-stroke 0.3s ease-out;

    &:hover {
      @include PcScale {
        -webkit-text-stroke: 0.065vw #01b577;
        color: #01b577;
      }
    }
  }
}
